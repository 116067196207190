import React, { useState, useCallback } from 'react';
import { ReactReader } from 'react-reader';
import { useSwipeable } from 'react-swipeable';

const TestEpubPage = () => {
  const [location, setLocation] = useState(
    localStorage.getItem('testEpubLocation') || 'epubcfi(/6/2[cover]!/6)'
  );

  const locationChanged = useCallback((newLocation) => {
    setLocation(newLocation);
    localStorage.setItem('testEpubLocation', newLocation); // Save location to localStorage
  }, []);

  // Swipe handlers
  const handleSwipeLeft = () => {
    const rendition = document.querySelector('iframe')?.contentWindow?.rendition;
    rendition?.next();
  };

  const handleSwipeRight = () => {
    const rendition = document.querySelector('iframe')?.contentWindow?.rendition;
    rendition?.prev();
  };

  const handlers = useSwipeable({
    onSwipedLeft: handleSwipeLeft,
    onSwipedRight: handleSwipeRight,
    preventDefaultTouchmoveEvent: false, // Do not block native text selection
    trackMouse: true,
    delta: 50,
  });

  return (
    <div
      {...handlers} // Attach swipe handlers to the outer container
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '100vh',
        backgroundColor: 'transparent', // Transparent background for the container
        padding: '20px',
      }}
    >
      <h1>React Reader Test</h1>
      <div
        style={{
          width: '80%',
          height: '70vh',
          border: '1px solid #ccc',
          borderRadius: '8px',
          overflow: 'hidden',
          position: 'relative',
          backgroundColor: 'transparent',
        }}
      >
        {/* EPUB Reader */}
        <ReactReader
          location={location}
          locationChanged={locationChanged}
          url="/epubs/the_art_of_war.epub"
          showToc={false} // Disable the table of contents
          swipeable={false} // Disable built-in swipe to preserve text selection
          getRendition={(renditionInstance) => {
            renditionInstance.themes.register('transparent', {
              body: {
                background: 'transparent',
                color: '#000',
              },
              p: {
                color: '#000',
              },
              a: {
                color: '#1e90ff',
              },
            });
            renditionInstance.themes.select('transparent');
          }}
        />
      </div>
    </div>
  );
};

export default TestEpubPage;
