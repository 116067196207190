import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { EpubView } from 'react-reader';
import './BookPage.css';
import { Bookshelf, Lightbulb, Journal, PencilSquare, Collection, Highlighter } from 'react-bootstrap-icons';
import SearchBar from './OpenAI';
import { Form, Button, FormControl } from 'react-bootstrap';
import { useSwipeable } from 'react-swipeable';

const PrevIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M10 12.796V3.204L4.519 8zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753" />
  </svg>
);

const NextIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
    <path d="M6 12.796V3.204L11.481 8zm.659.753 5.48-4.796a1 1 0 0 0 0-1.506L6.66 2.451C6.011 1.885 5 2.345 5 3.204v9.592a1 1 0 0 0 1.659.753" />
  </svg>
);

const BookPage = () => {
  const { title, page } = useParams();
  const navigate = useNavigate();
  const [book, setBook] = useState(null);
  const [location, setLocation] = useState(decodeURIComponent(page || localStorage.getItem(`location-${title}`) || '1'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [note, setNote] = useState('');
  const [isInReadingList, setIsInReadingList] = useState(false);
  const [isSwipeEnabled, setIsSwipeEnabled] = useState(true);
  const epubRef = useRef(null);
  const [previousPage, setPreviousPage] = useState('/');

  useEffect(() => {
    if (!title) {
      setError('Book title is missing');
      setLoading(false);
      return;
    }

    const decodedTitle = decodeURIComponent(title);
    const storedPreviousPage = localStorage.getItem('previousPage') || '/';
    setPreviousPage(storedPreviousPage);

    fetch('/books.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        return response.json();
      })
      .then((data) => {
        const selectedBook = data.find((book) => book.title === decodedTitle);
        if (selectedBook) {
          setBook(selectedBook);
          const readingList = JSON.parse(localStorage.getItem('readingList')) || [];
          if (readingList.some((b) => b.title === selectedBook.title)) {
            setIsInReadingList(true);
          }
        } else {
          setError('Book not found');
        }
      })
      .catch((error) => {
        console.error('Fetch error:', error);
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [title]);

  const handleLocationChanged = (newLocation) => {
    const encodedLocation = encodeURIComponent(newLocation);
    setLocation(newLocation);
    localStorage.setItem(`location-${title}`, newLocation);
    navigate(`/book/${encodeURIComponent(title)}/${encodedLocation}`);
  };

  const handleNext = () => {
    if (epubRef.current) {
      epubRef.current.nextPage();
    }
  };

  const handlePrev = () => {
    if (epubRef.current) {
      epubRef.current.prevPage();
    }
  };

  const handleToggleSwipe = () => {
    setIsSwipeEnabled(!isSwipeEnabled);
  };

  const handleLearnClick = () => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/learn/book/${encodeURIComponent(title)}`);
  };

  const handleNoteClick = () => {
    localStorage.setItem('previousPage', window.location.pathname);
    navigate(`/${encodeURIComponent(title)}/notes`);
  };

  const handleAddNoteClick = () => {
    setShowNoteModal(true);
  };

  const handleNoteChange = (event) => {
    setNote(event.target.value);
  };

  const handleSaveNote = () => {
    if (!note.trim()) {
      alert("Note can't be empty!");
      return;
    }
    const notes = JSON.parse(localStorage.getItem(`notes-${title}`)) || [];
    const now = new Date();
    const timestamp = now.toLocaleString();
    const newNote = {
      text: note,
      location,
      timestamp,
    };
    notes.push(newNote);
    localStorage.setItem(`notes-${title}`, JSON.stringify(notes));
    setShowNoteModal(false);
    setNote('');
  };

  const handleDismissNote = () => {
    setShowNoteModal(false);
    setNote('');
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => isSwipeEnabled && epubRef.current?.nextPage(),
    onSwipedRight: () => isSwipeEnabled && epubRef.current?.prevPage(),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    delta: 10,
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!book) return <div>Book not found</div>;

  return (
    <>
    <br></br>
      <div id="spacer"></div>
      <SearchBar bookTitle={book.title} />
      <div className="book-page">
        <div
          {...handlers}
          className="swipeable-container"
          style={{
            height: '67vh',
            zIndex: isSwipeEnabled ? 10 : -1,
            position: 'absolute',
            width: '100%',
            touchAction: isSwipeEnabled ? 'none' : 'auto',
          }}
        />
        <div style={{ zIndex: 0, position: 'relative', width: '100%', height: '67vh' }}>
          {book.link ? (
            <EpubView
              ref={epubRef}
              url={book.link}
              location={location}
              locationChanged={handleLocationChanged}
            />
          ) : (
            <div>No EPUB link available</div>
          )}
        </div>
        <div id="pageNavigation">
          <button id="navigationButton" onClick={handlePrev} aria-label="Previous Page">
            <PrevIcon />
          </button>
          <button id="navigationButton" onClick={handleNext} aria-label="Next Page">
            <NextIcon />
          </button>
        </div>
        <div className="navigation-controls">
          <div className="other-buttons-row">
            <button id="standardButtonNav" onClick={handleNoteClick} className="nav-button" aria-label="Notes">
              <Journal />
              Notes
            </button>
            <button id="standardButtonNav" onClick={handleAddNoteClick} className="nav-button" aria-label="Add Note">
              <PencilSquare />
              Add Note
            </button>
            <button id="standardButtonNav" onClick={() => navigate(previousPage)} className="nav-button">
              <Bookshelf />
              Library
            </button>
            <button
              id="standardButtonNav"
              onClick={handleToggleSwipe}
              className="nav-button"
              aria-label={isSwipeEnabled ? 'Disable Swipe' : 'Enable Swipe'}
            >
               {isSwipeEnabled ? <Highlighter/> : <svg xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px" fill="#000000"><path d="M443-96q-24 0-46-9t-39-26L160-329l31-31q14-14 32-18.5t37 .5l76 19v-325q0-15.3 10.29-25.65Q356.58-720 371.79-720t25.71 10.35Q408-699.3 408-684v419l-111-28 111 111q7 7 16.13 10.5 9.13 3.5 18.87 3.5h157q39.6 0 67.8-28.2Q696-224.4 696-264v-180q0-15.3 10.29-25.65Q716.58-480 731.79-480t25.71 10.35Q768-459.3 768-444v180q0 70-49 119T600-96H443Zm13-288v-156q0-15.3 10.29-25.65Q476.58-576 491.79-576t25.71 10.35Q528-555.3 528-540v156h-72Zm120 0v-107.74q0-15.26 10.29-25.76 10.29-10.5 25.5-10.5t25.71 10.35Q648-507.3 648-492v108h-72Zm-38 102Zm326-390H672v-48h113q-53-72-134-108t-171-36q-90 0-171 36T175-720h113v48H96v-192h48v106q60-78 149-116t187-38q98 0 187 38t149 116v-106h48v192Z"/></svg>}
              {isSwipeEnabled ? 'Annotate' : 'Swipe'}
            </button>
            <button id="standardButtonNav" onClick={handleLearnClick} className="nav-button" aria-label="Learn More">
              <Lightbulb />
              Learn
            </button>
          </div>
        </div>
        <br></br><br></br>
      </div>
      {showNoteModal && (
     <div className="search-modal-overlay">
     <div className="search-modal">
     <button className="close-button" onClick={handleDismissNote}>
         &times;
       </button>
       <Form className="search-result-content">
         <Form.Group controlId="noteForm">
           <Form.Label>Add a Note</Form.Label>
           <FormControl
             as="textarea"
             rows={3}
             value={note}
             onChange={handleNoteChange}
           />
         </Form.Group>
         <Button id='standardButton'  onClick={handleSaveNote}>Save</Button>
       </Form>
     </div>
   </div>
      )}
    </>
  );
};

export default BookPage;
