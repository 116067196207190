import React, { useState, useEffect } from 'react';
import JSZip from 'jszip';
import Epub from 'epubjs';

const LoadAndDisplayBook = ({ book }) => {
  const [epubUrl, setEpubUrl] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (book && book.link) {
      loadAndUnzip(book.link);
    }
  }, [book]);

  const loadAndUnzip = async (zipFilePath) => {
    try {
      // Fetch the zip file
      const response = await fetch(zipFilePath);
      if (!response.ok) throw new Error('Failed to fetch ZIP file.');

      const zipBlob = await response.blob();
      const zip = await JSZip.loadAsync(zipBlob);

      // Assuming there's a file in the ZIP called "The Alchemist.epub"
      const epubFile = zip.file(book.title + '.epub.zip');
      if (epubFile) {
        const epubBlob = await epubFile.async('blob');
        setEpubUrl(URL.createObjectURL(epubBlob)); // Create a URL to the blob
      } else {
        throw new Error('EPUB file not found in ZIP archive.');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  // Render the EPUB with epub.js if the epubUrl is available
  const renderEpub = () => {
    if (epubUrl) {
      const book = Epub(epubUrl);
      const rendition = book.renderTo("book", {
        width: "100%",
        height: "600px",
      });
      rendition.display();
    }
  };

  // Trigger the EPUB render once the url is set
  if (epubUrl) {
    renderEpub();
  }

  return (
    <div>
      {error && <p>Error: {error}</p>}
      {epubUrl && (
        <div id="book" style={{ width: '100%', height: '600px' }}></div>
      )}
    </div>
  );
};

export default LoadAndDisplayBook;
